.location-picker-component .ant-select-selector {
  height: 50px !important;
  min-width: 250px !important;
  width: 300px !important;
  align-items: center;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.location-picker-component .ant-select-selection-search-input {
  height: 50px !important;
  min-width: 250px !important;
  width: 280px !important;
  font-family: "Roboto", sans-serif;
}

.location-picker-component.ant-select.ant-select-disabled {
  background-color: white !important;
  opacity: 0.8 !important;
  border-radius: 5px;
}

@media (max-width: 820px) {
  .location-picker-component .ant-select-selector {
    height: 40px !important;
    min-width: 350px !important;
    width: 100% !important;
  }

  .location-picker-comonent .ant-select-selection-search-input {
    height: 30px !important;
    min-width: 350px !important;
    width: 100% !important;
    font-family: "Roboto", sans-serif;
  }
}
