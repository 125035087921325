.PopupConfirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  font-family: "Roboto", sans-serif;
}

.Button-close-popup {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.PopupConfirm-headerText {
  font-weight: bold;
  font-size: 30px;
  justify-content: center;
  text-align: center;
}

.PopupConfirm-subDisplay {
  background-color: white;
  width: 750px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  justify-content: space-between;
  bottom: 125px;
  position: relative;
}

.PopupConfirm-subText {
  font-size: 20px;
  text-align: center;
  color: rgb(126, 130, 135);
}

.PopupConfirm-iconDisplay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 20px;
}

.PopupConfirm-subDisplay-reject {
  background-color: white;
  width: 500px;
  height: 350px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  justify-content: space-between;
}

.PopupConfirm-reason-textarea {
  font-size: 16px;
  padding: 10px;
}
