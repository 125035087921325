.CalendarPage-bookingList {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
  background-color: #d9d9d9;
  gap: 10px;
  font-family: "Roboto", sans-serif;
}

.bookingList-title {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
}

.CalendarPage-bookingListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoBookingListDisplay {
  text-align: center;
  width: 330px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  color: #a7a7a7;
  justify-content: center;
  align-self: center;
}

.BookingList-filterPanel {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
}

.BookingList-checkbox {
}
