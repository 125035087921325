.FlightDetailBox {
  height: 300px;
  width: 300px;
  background-color: white;
  border: none;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 3;
  position: absolute;
  margin-top: 90px;
}

.FlightBox {
  width: 250px;
  height: 50px;
  background-color: white;
  margin-bottom: 2px;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}

.FlightClassText {
  color: black;
  display: flex;
  align-self: start;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 20px;
}

.placeHolderFlightText {
  color: black;
  font-size: 16px;
  display: flex;
  align-self: start;
  align-items: center;
  margin-left: 15px;
  width: 250px;
  height: 50px;
}

.passengerTypeBox {
  font-size: 16px;
  color: black;
  display: flex;
  align-self: start;
  margin-left: 15px;
}

.subPassengerTypeText {
  color: #393e46;
  opacity: 50%;
  margin-top: 2px;
  width: 120px;
  display: flex;
  align-self: start;
}

.passengerTypeText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputNumber {
  display: flex;
  justify-content: flex-end;
  width: 150px;
}

.restrictionText {
  color: #393e46;
  opacity: 50%;
  font-size: 14px;
  margin-top: 26px;
}

.ant-select-selection-item {
  font-family: "Roboto", sans-serif !important;
}

/* .selectFlightClassAntD :where(.css-dev-only-do-not-override-190m0jy).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    font-family: 'Roboto', sans-serif !important;
    width: 250px !important;
    margin-bottom: 20px !important;
    text-align: start !important;
} */

@media (max-width: 820px) {
  .FlightBox {
    width: 100%;
    min-width: 250px;
    height: 40px;
  }

  .placeHolderFlightText {
    height: 40px;
  }

  .FlightDetailBox {
    width: 100%;
    min-width: 300px;
    max-width: 330px;
    justify-self: flex-start;
  }

  .inputNumber {
    width: 170px;
  }
}
