.hotelBooking-buttonPanel{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 370px;
    align-self: flex-end;
    margin-right: 120px;
}

.BookingManagement-Page{
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    padding: 20px;
    width: 1000px;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: auto;
}

.BookingManagementPage-header{
    font-size: 30px;
    font-weight: bold;
    align-self: start;
    margin-left: 100px;
}

.BookingManagementPage-pariticipant{
    display: flex;  
}