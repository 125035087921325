.PopupCalendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  font-family: "Roboto", sans-serif;
}

.PopupCalendar-closeButton {
  cursor: pointer;
  width: fit-content;
  height: fit-content;
}

.PopupCalendar-subDisplay {
  background-color: white;
  width: 500px;
  min-height: 500px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 30px;
  border-radius: 10px;
  /* justify-content: space-between; */
}

.PopupCalendar-title {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.PopupCalendar-hotelFlex {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.PopupCalendar-guestSection {
  margin-top: 20px;
}
