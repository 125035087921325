.TripgenSearchBar-container {
  background-color: #222831;
  color: #ffffff;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.TripgenSearchBar-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 60px;
  display: flex;
  flex-direction: row;
}

.TripgenSearchBar-flightSearch {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.TripgenSearchBar-fromComponent,
.TripgenSearchBar-toComponent,
.TripgenSearchBar-departComponent,
.TripgenSearchBar-returnComponent,
.TripgenSearchBar-passengersComponent {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TripgenSearchBar-CheckboxPanel {
  gap: 15px;
  display: flex;
  flex-direction: row;
  margin-left: 150px;
  margin-top: 15px;
}

.TripgenSearchBar-myCheckbox {
  gap: 15px;
  display: flex;
  flex-direction: row;
}

.TripgenSearchBar-searchPanel {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.TripgenSearchBar-searchButton {
  justify-content: center;
  align-self: center;
  display: flex;
  margin-right: auto;
  width: 100%;
}

.TripgenSearchBar-arrowUpButton {
  cursor: pointer;
  margin-left: auto;
  position: relative;
  right: 1%;
}

.TripgenSearchBar-addMoreButton {
  cursor: pointer;
  font-size: 14px;
  justify-content: center;
  display: flex;
  height: 20px;
  align-items: center;
  gap: 10px;
}
.TripgenSearchBar-arrowDownButton {
  cursor: pointer;
  align-self: flex-end;
  justify-content: flex-end;
  display: flex;
  position: absolute;
  margin-left: auto;
  margin-right: 20px;
  width: 100%;
}

.TripgenSearchBar-shortenTabbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  font-size: 14px;
  font-weight: normal;
  margin: auto;
  padding: 10px;
}
