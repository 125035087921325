.filterHotel-container {
  border-right: 1px solid black;
  width: 300px;
  height: fit-content;
  font-family: "Roboto", sans-serif;
  position: sticky;
  top: 0;
  padding: 20px;
}

.FilterHotel-header {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 700;
}

.FilterHotel-ant-slider {
  width: 250px;
  display: flex;
  margin: auto;
}

.FilterHotel-yourBudgetInputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.FilterHotel-inputs-separator {
  margin: 0 8px;
}

.FilterHotel-propertyTypeText,
.FilterHotel-distanceToCenterText,
.FilterHotel-bedTypeText,
.FilterHotel-paymentOptionsText,
.FilterHotel-roomAmenitiesText,
.FilterHotel-propertyFacilitiesText,
.FilterHotel-yourBudgetText {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 700;
}

.FilterHotel-ant-radio-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.transparent-button2 {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: center;
  justify-content: center;
  justify-self: center;
}

.last-gap {
  padding-bottom: 20px;
}

.FilterHotel-propertyFacilitiesRadioButton {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 2px;
}

.FilterHotel-antdChoice {
  font-size: 16px;
}
