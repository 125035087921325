.payment-card {
  border-radius: 5px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #818181;
  margin: 20px;
  padding: 20px;
  width: 700px;
}

.payment-card:hover {
  transform: scale(1.005);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.payment-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.payment-form-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.CreditOrDebitCard{
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

.AvailablePaymentMethod{
  font-size: 20px;
  font-weight: bold;
}