.table {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #001529; /* Set background color for header cells */
  color: #ed0505;
}

.request-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wallet-dropdown {
  align-self: flex-end;
  display: flex;
  align-items: center;
  margin-right: 20px; /* Add margin to the right */
  padding-left: 0; /* Reset padding for block elements */
  padding-right: 20px; /* Add padding to the right */
}

.wallet-header {
  margin-right: 10px; /* Adjust the margin as needed */
  font-family: "Roboto", sans-serif;
}

.table-container {
  background-color: #001529; /* Set background color for header cells */
  color: #ed0505;
}

.ant-table-thead .ant-table-cell {
  background-color: rgb(196, 196, 196) !important;
}
