.flightInfo-container {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.flightInfo-first-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.flightInfo-second-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1.7fr 2fr 2fr;
}

.flightInfo-third-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr;
}
