.flight-search-bar-container {
  background-color: #222831;
  color: #ffffff;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.searchBox {
  display: flex;
  gap: 10px;
}

.fromComponent,
.toComponent,
.departComponent,
.returnComponent,
.passengersComponent {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.searchComponent {
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.myCheckboxSearchBox {
  display: flex;
  margin-left: 0;
  font-size: 16px;
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.FlexibleCheckbox {
  display: flex;
  margin-left: 0;
  font-size: 16px;
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
}

.Checkbox-panel-searchbar {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 15px;
}

@media (max-width: 820px) {
  .searchBox {
    flex-direction: column;
  }

  .fromComponent
    .toComponent
    .departComponent
    .returnComponent
    .passengersComponent {
    width: 100%;
    font-size: 16px;
  }

  .searchComponent {
    margin-left: 0;
    text-align: center;
  }

  .myCheckboxSearchBox,
  .FlexibleCheckbox {
    display: flex;
    margin-left: 0;
    font-size: 14px;
    gap: 10px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .Checkbox-panel-searchbar {
    gap: 25px;
  }

  .searchComponent .button_basic {
    width: 100% !important;
    min-width: 250px !important;
    height: 40px !important;
    margin-bottom: 10px !important;
  }
}
