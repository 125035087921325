.HotelBooking-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 700px;
    height: fit-content;
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    gap:5px;
}

.HotelBooking-hotelName{
    display: flex;
    flex-direction: row;
}

.blacken {
    color: black;
    font-weight: bold;
    margin-right: 10px;
}

.HotelBooking-type,
.Hotelbooking-walletPanel{
    display: flex;
    flex-direction: row;
}

.Hotelbooking-gird{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.Hotelbooking-requestOffer,
.Hotelbooking-currentOffer{
    width: auto;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap:5px;
    border: 1px solid black;
    padding: 10px;
    border-radius: 5px;
}

.Hotelbooking-notEqual{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 550px;
}

.Hotelbooking-amenities{
    display: flex;
    flex-direction: row;
}