.TabBar-all {
  display: flex;
  text-align: center;
  align-items: center;
  height: 70px;
  background-color: #536872;
}

.TabBar-flight,
.TabBar-hotel,
.TabBar-car,
.TabBar-admin,
.TabBar-calendar,
.TabBar-tripgen {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  width: 150px;
  height: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(83, 104, 114, 0.4);
  transition: background-color 0.3s ease;
}

.TabBar-flight.active,
.TabBar-hotel.active,
.TabBar-car.active,
.TabBar-admin.active,
.TabBar-calendar.active,
.TabBar-tripgen.active {
  background-color: #222831;
}

.TabBar-icon {
  display: flex;
  align-self: center;
}

.flight-hotel-car-tabbar {
  display: flex;
  flex-direction: row;
}

.LogoFastPlanner {
  font-size: 30px;
  font-weight: bolder;
  color: white;
  margin-top: 25px;
  margin-left: 30px;
  margin-bottom: 30px;
  margin-right: 50px;
  font-family: "Roboto", sans-serif;
  display: flex;
}

@media (max-width: 820px) {
  .TabBar-all {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .TabBar-flight,
  .TabBar-hotel,
  .TabBar-car,
  .TabBar-admin {
    font-size: 16px;
    /* width: 100%; */
    /* margin: 5px 0; */
    height: 50px;
    min-width: 80px;
    width: 100%;
  }

  .LogoFastPlanner {
    font-size: 24px;
    margin: 5px 0;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 15px;
    text-align: center;
    min-width: 110px;
    width: 100%;
    justify-content: flex-start;
  }

  .flight-hotel-car-tabbar {
    width: 100%;
  }
}

.TabBar-login {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  gap: 10px;
  width: 150px;
  height: 70px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(83, 104, 114, 0.4);
  transition: background-color 0.3s ease;
  position: absolute;
  right: 0;
  top: 0;
}

.TabBar-login:hover {
  background-color: #343d4b;
}

.TabBar-tripgen-submenu {
  position: absolute;
  top: calc(100%);
  left: 0;
  background-color: #343d4b;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  width: 150px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.TabBar-tripgen:hover .TabBar-tripgen-submenu {
  display: block; /* Show submenu on hover */
}

.submenu-item {
  /* Styles for submenu items */
  color: #ffffff;
  cursor: pointer;
  padding: 5px 0;
  font-size: 16px;
  font-weight: normal;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.TabBar-tripgen-submenu.active {
  background-color: #343d4b;
}

.submenu-item:hover {
  background-color: #475766;
}

.TabBar-tripgen {
  position: relative;
}

.TabBar-tripgen-main {
  display: flex;
  align-items: center;
  gap: 10px;
}
