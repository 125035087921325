.button_basic {
    border-radius: 50px;
    left: 37.94%;
    right: 38.26%;
    top: 19.35%;
    bottom: 14.52%;
    border: none;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 27px;
    color: white;
    text-align: center;
    width: 200px;
    height: 62px;
    left: 565px;
    top: 622px;
    background: #00ADB5;
    cursor: pointer;
}

.button_basic:hover {
    background: #a2a185 !important;
    filter: drop-shadow(0px 4px 4px rgba(113, 113, 113, 0.25));
    color: white;
}