.flight-detail-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 2;
  position: sticky;
  top: 0;
}

.flight-detail-itinerary {
  width: 100%;
}

.flight-detail-itinerary h4 {
  margin: 0px;
}

.flight-detail-itinerary-header-description {
  display: flex;
  font-size: 12px;
}

.flight-detail-itinerary-header-description p {
  margin: 5px 0px;
}

.flight-detail-segment {
  display: flex;
  justify-content: space-between;
}

.flight-detail-segments {
  position: relative;
}

.flight-detail-segments::before {
  content: "";
  width: 1px;
  height: 100%;
  /* padding-bottom: 20px; */
  position: absolute;
  background: linear-gradient(
      rgb(255, 255, 255),
      rgb(255, 255, 255) 45%,
      rgb(26, 26, 26) 55%,
      rgb(26, 26, 26)
    )
    0% 0% / 100% 8px;
}

.flight-detail-segment-schedule {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
  position: relative;
}

.flight-detail-segment-schedule p {
  font-size: 12px;
  margin: 5px;
}

.flight-detail-segment-schedule h5 {
  margin: 0px 5px;
}

.circle {
  position: absolute;
  width: 0px;
  height: 100%;
  margin-top: 4px;
  top: 0px;
  left: 0px;
  border-left: 1px solid rgb(26, 26, 26);
}

.circle::after {
  bottom: 0px;
}

.circle::before,
.circle::after {
  content: "";
  position: absolute;
  left: -8px;
  box-sizing: content-box;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: 2px solid black;
  background-color: #ffffff;
}

.flight-detail-segment-airline {
  display: flex;
  right: 0px;
}

.flight-detail-segment-airline p {
  font-size: 12px;
  margin: 5px;
}

.flight-segment-layover {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  padding: 20px;
}
