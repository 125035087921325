.TripgenPage-resultAndFilter {
  display: flex;
  gap: 10px;
  max-width: 100%;
}

.TripgenPage-info-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 60px;
  padding: 20px;
}

.TripgenPage-step-handler {
  display: flex;
  gap: 20px;
  padding: 20px;
  position: absolute;
  top: 750px;
  right: 400px;
}

.TripgenFligt-form {
  display: flex;
  justify-content: center;
  width: fit-content;
  margin-left: 250px;
}

.TripgenPage-payment-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.TripgenPage-payment-buttonPanel {
  padding: 20px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 700px;
}

.Tripgen-FlightHotel-title {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
}

.Tripgen-Confirm-Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  max-height: 600px;
  overflow: auto;
  width: 800px;
  border-radius: 5px;
  border: 1px solid #797979;
  margin-top: 10px;
}

.Tripgen-confirm-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Tripgen-confirm-buttonPanel {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
}

.Tripgen-confirm-subpage {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.TripgenPage-stepcomponent {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
  /* gap: 10px; */
}

.TripgenPage-stepcomponent > *:not(:first-child) {
  margin-top: 10px;
}
.TripgenPage-stepcomponent > * {
  margin-top: 10px;
}

.TripgenPage-resultAndDetail {
  display: flex;
  flex-direction: column;
  width: calc(100% - 400px);
}

.TripgenPage-filterFlightSection {
  display: flex;
  position: sticky;
  top: 0;
}

.TripgenPage-buttonToShowFilter {
  width: 40px;
  height: 40px;
  background-color: #0074e4;
  border-radius: 0 50% 50% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  position: relative;
  margin-top: 10px;
  position: sticky;
  top: 0;
}

.TripgenPage-displayTotalText {
  font-family: "Roboto", sans-serif;
  margin-left: 20px;
  margin-top: 10px;
}

.TripgenPage-flightDetailContainer {
  width: 400px;
  margin-left: 20px;
  margin-right: 40px;
}

.TripgenPage-searchHotelContainer {
  display: flex;
}

.TripgenPage-filterHotelSection {
  display: flex;
}

.TripgenPage-flightItemDisplay {
  margin-left: 10px;
  margin-right: 20px;
}

.TripgenPage-hoteltItemContainer {
  margin-left: 50px;
}

.TripgenPage-noTtemText {
  margin-top: 30px;
  font-size: 24px;
  color: rgb(180, 174, 174);
  font-family: "Roboto", sans-serif;
  width: 500px;
  display: flex;
  justify-content: center;
}
