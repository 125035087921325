.RoomAndGuest-container {
  width: 250px;
  height: 50px;
  background-color: white;
  margin-bottom: 2px;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}

.RoomAndGuest-box {
  height: 150px;
  width: 250px;
  background-color: white;
  border: none;
  border-radius: 20px;
  font-family: "Roboto", sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 3;
  position: absolute;
  margin-top: 90px;
}

.RoomAndGuest-PlaceHolderText {
  color: black;
  font-size: 16px;
  display: flex;
  align-self: start;
  align-items: center;
  margin-left: 15px;
  width: 250px;
  height: 50px;
}

.RoomAndGuest-Panel {
  font-size: 16px;
  color: black;
  display: flex;
  align-self: start;
  margin-left: 15px;
  /* width: 220px; */
}

.RoomAndGuest-NumberInput {
  display: flex;
  justify-content: flex-end;
  width: 150px;
}

.RoomAndGuest-Text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
  width: 70px;
}

.ant-select-selection-item {
  font-family: "Roboto", sans-serif !important;
}

.RoomAndGuest-RoomAdultChildren {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}
