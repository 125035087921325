.BookingListPage-grid {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  gap: 20px;
}

.BookingListPage-Calendar {
  width: calc(100% - 20px); /* Adjust width to accommodate padding */
  height: 500px;
}

.BookingListContainer {
  width: calc(100% - 20px); /* Adjust width to accommodate padding */
  max-width: 300px; /* Set maximum width for the booking list */
  padding: 0 20px; /* Add padding to the booking list container */
}

.filter-wrapper {
  display: flex;
  align-items: center; /* Align items vertically */
  padding-bottom: 20px;
  padding-left: 50px;
}

.filter-type {
  font-size: 18px; /* Increase font size */
  padding-right: 20px; /* Add more padding */
  font-weight: bold;
}
