.FlightInformation-container {
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 450px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 10px;
  border: 1px solid #b4b4b4;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.FlightInformation-pricePanel {
  display: flex;
  flex-direction: row;
  height: 30px;
  width: 350px;
  margin-left: 15px;
  font-size: 24px;
  font-weight: bold;
  justify-content: space-between;
  margin-top: auto;
}

.FlightInformation-startToEndLocation {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-left: 35px;
  margin-bottom: 10px;
}

.FlightInformation-3letter,
.FlightInformation-stopAndDuration {
  display: flex;
  margin-top: 5px;
  margin-left: 35px;
  margin-bottom: 10px;
}

.FlightInformation-header {
  font-size: 22px;
  font-weight: bolder;
  margin-top: 15px;
  margin-bottom: 15px;
}

.FlightInformation-subHeader {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  margin-left: 15px;
  margin-bottom: 10px;
}

.FlightInformation-typeOfFlight {
  margin-left: 15px;
  display: flex;
  margin-bottom: 5px;
}

.FlightInformation-line {
  background-color: gray;
  opacity: 60%;
  height: 2px;
  width: 330px;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 15px;
}

.FlightInformation-DateAndDuration {
  display: flex;
  flex-direction: row;
  margin-left: 35px;
}

.normal-space-flightComponent {
  font-weight: bold;
  margin-right: 10px;
}

.FlightInformation-durationHourAndMinute {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}
