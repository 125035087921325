.confirm-container {
  height: 521px;
  width: 695px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #818181;
  border-radius: 10px;
  margin: 10px;
  padding: 0px 20px 10px 20px;
  max-height: 521px;
  overflow-y: auto;
}

.passenger-info {
  display: grid;
  grid-template-rows: 1fr 1fr; /* 4 rows with equal height */
  grid-template-columns: 1fr 1fr 1fr 1fr; /* 2 columns with equal width */
  gap: 10px;
  padding-left: 20px;
}

.passenger-info2 {
  display: grid;
  grid-template-rows: 1fr 1fr; /* 4 rows with equal height */
  grid-template-columns: 1fr 1fr 1fr; /* 2 columns with equal width */
  gap: 10px;
  padding-left: 20px;
}

.confirm-item {
  font-size: 12px;
}

.passenger-info-header {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0px 15px 0px;
}

.contact-info {
  /* padding-top: 30px; */
}
