.search-hotel-container {
  display: flex;
}

.searchHotelPage-container {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}

.search-page-filterHotel-section {
  display: flex;
}

.search-page-hoteltItemContainer {
  /* margin-left: 10px;
    margin-top: 20px;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 20px;
    overflow: auto;
    width: fit-content;
    max-height: 600px;
    height: fit-content; */
}

.search-page-no-item-text {
  margin-top: 30px;
  font-size: 24px;
  color: rgb(180, 174, 174);
  font-family: "Roboto", sans-serif;
  width: 500px;
  display: flex;
  justify-content: center;
}

.custom-back-top .ant-back-top-icon {
  font-size: 30px; /* Adjust the size as needed */
}
