/* App.css */
.custom-modal {
    animation: fade 0.3s;
}

.loading-text{
    font-size: 30px;
    font-weight: bold;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blank-space-modal-loading {
    height: 200px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-right: 27%;
    padding-bottom: 20%;
}

lds-ring {
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 200px;
    height: 200px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00ADB5 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}