.guest-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.guest-info-header {
  display: flex;
  gap: 20px;

  align-items: center;
  justify-content: space-between;
}

.guest-info-header-left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.guest-info-traveller-type {
  width: 100px;
  height: 30px;
  text-align: center;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #818181;
}

.guest-info-data {
  display: grid;
  grid-template-rows: repeat(1fr, 4);
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

.guest-info-remove-guest-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100px;
  height: 30px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #818181;
  background-color: #ffffff;
  justify-self: flex-end;
}

.guest-info-remove-guest-btn:hover {
  cursor: pointer;
  background-color: #ffabab;
}

.guest-info-data-item {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  height: 30px;
  padding: 0px 10px;
}

.guest-info-data-item-title {
  height: 30px;
  width: 248px;
  padding: 0px 0px;
}

.disables-btn:hover {
  cursor: default;
  background-color: #ffffff;
}
