.Participant-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 700px;
    height: fit-content;
    border-radius: 10px;
    border: 1px solid black;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    gap: 5px;
}

.Participant-booker{
    display: flex;
    flex-direction: row;
}
.Participant-participants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Participant-each {
    flex-basis: 100%;
    margin-top: 5px;
}

.participant-number {
    font-weight: bold;
    margin-right: 5px;
}
