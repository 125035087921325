.flight-booking-page {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flight-booking-page-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 20px;
  padding: 20px;
}

.flight-booking-page-left {
  justify-self: flex-end;
}

.flight-booking-page-right {
  display: flex;
  flex-direction: column;
}

.flight-booking-page-step-handler {
  display: flex;
  gap: 20px;
  padding: 20px;
}

.flight-booking-page-btn {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 0px;
  background-color: #00adb5;
  color: #ffffff;
}

.back-btn {
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  color: #000000;
}

.next-btn {
  background-color: #00adb5;
  color: #ffffff;
}

.flight-booking-page-btn:hover {
  cursor: pointer;
}
