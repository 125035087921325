.datepicker-component {
  width: 203px;
  height: 50px;
}

.datepicker-component .ant-picker-input input {
  font-size: 16px !important;
  font-family: "Roboto", sans-serif !important;
}

.datepicker-component.ant-picker.ant-picker-disabled {
  background-color: white !important;
  opacity: 0.8 !important;
}

@media (max-width: 820px) {
  .datepicker-component {
    min-width: 300px;
    width: 100%;
    height: 40px;
  }
}
