.TripgenHotelSearch-fromComponentHotel
  .location-picker-component
  .ant-select-selector {
  width: 620px !important;
}

.TripgenHotelSearch-differentHotelContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.TripgenHotelSearch-differentHotelChecked {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.TripgenHotelSearch-fromComponentHotel,
.TripgenHotelSearch-toComponent,
.TripgenHotelSearch-departComponent,
.TripgenHotelSearch-returnComponent,
.TripgenHotelSearch-passengersComponent {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
