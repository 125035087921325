.hotelInfo-container {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
}

.hotelInfo-first-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 4fr 2fr 2fr;
}

.hotelInfo-second-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
