.hotel-booking-page {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.hotel-booking-page-content {
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 20px;
  padding: 20px;
}
