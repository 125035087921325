.flight-item-container {
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin: 10px;
  padding: 20px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #818181;

  font-size: 12px;
}

.flight-item-container :hover {
  cursor: pointer;
}

.flight-item-container p {
  margin: 5px 0;
}

.flight-item-selected {
  border: 2px solid #00adb5;
  box-shadow: none;
}

.flight-item-info-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flight-item-info-item {
  display: flex;
  align-items: center;
  gap: 40px;
}

.flight-item-info-airline {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100px;
}

.flight-item-time-airport {
  display: flex;
  flex-direction: column;
}

.flight-item-time {
  font-size: 18px;
}

.flight-item-airport {
  font-size: 12px;
}

.flight-item-duration-stop {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flight-item-stop-line {
  display: flex;
  width: 200px;
  align-items: center;
  gap: 5px;
}

.flight-item-stop-text {
  display: flex;
  gap: 10px;
}

.flight-item-price-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 200px;
  border-left: solid 1px #cdcdcd;
  padding-left: 20px;
}

.flight-display-interface {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

@media (max-width: 820px) {
  .flight-item-container {
    font-size: 12px;
    width: 90%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .flight-item-info-section {
    gap: 0px;
  }

  .flight-item-info-item {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 15px;
  }

  .flight-item-info-airline {
    width: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    min-width: 150px;
    gap: 20px;
    text-align: center;
    font-size: 14px;
    order: 2;
  }

  .flight-display-interface {
    gap: 20px;
  }

  .flight-item-duration-stop {
    gap: 5px;
  }

  .flight-item-price-section {
    border-left: none;
    border-top: solid 1px #cdcdcd;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    height: 50px;
    padding: 0;
  }

  .flight-item-stop-line {
    width: 100px;
  }
}
