.TripgenFlightSearch-container {
  background-color: #222831;
  color: #ffffff;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.TripgenFlightSearch-title {
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 60px;
}

.TripgenFlightSearch-flightSearch,
.TripgenFlightSearch-differentHotelChecked {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.TripgenFlightSearch-fromComponent,
.TripgenFlightSearch-toComponent,
.TripgenFlightSearch-departComponent,
.TripgenFlightSearch-returnComponent,
.TripgenFlightSearch-passengersComponent {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.TripgenFlightSearch-fromComponent
  .location-picker-component
  .ant-select-selector {
  width: 300px !important;
}

.TripgenFlightSearch-toComponent
  .location-picker-component
  .ant-select-selector {
  width: 300px !important;
}

.TripgenFlightSearch-CheckboxPanel {
  gap: 30px;
  display: flex;
  flex-direction: row;
  margin-left: 15%;
  margin-top: 15px;
  margin-bottom: 5px;
}

.TripgenFlightSearch-myCheckbox {
  gap: 15px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.TripgenFlightSearch-removeButton {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  height: fit-content;
  margin-top: 40px;
  margin-left: 20px;
  position: absolute;
}
