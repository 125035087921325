.contact-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-info-data {
  display: grid;
  grid-template-rows: repeat(1fr, 3);
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.contact-info-data-item {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  height: 30px;
  padding: 0px 10px;
}
