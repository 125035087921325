.HotelInformation-container {
  display: flex;
  flex-direction: column;
  width: 382px;
  height: 500px;
  text-align: center;
  font-family: "Roboto", sans-serif;
  border-radius: 10px;
  border: 1px solid #b4b4b4;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.HotelInformation-hotelName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 5px;
  gap: 5px;
}

.HotelInformation-hotelLocation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  gap: 5px;
  margin-top: 10px;
}

.HotelInformation-nightAndGuest {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 15px;
  gap: 12px;
}

.HotelInformation-roomQuantity {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 10px;
  gap: 12px;
}

.HotelInformation-amentity {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
  margin-top: 10px;
  gap: 12px;
}
