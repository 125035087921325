.hotoel-search-bar-container {
  background-color: #222831;
  color: #ffffff;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  padding: 20px;
}

.fromComponent-hotel .location-picker-component .ant-select-selector {
  width: 400px !important;
}

.fromComponent-hotel {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
