.FilterFlight-container {
  border-right: 1px solid black;
  width: 300px;
  height: fit-content;
  font-family: "Roboto", sans-serif;
  overflow: auto;
  position: sticky;
  top: 0;
  /* height: 600px; */
  padding: 20px;
}

.FilterFlight-header {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  font-size: 24px;
  font-weight: 700;
}
.FilterFlight-radioButtonGroup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 20px;
  margin-bottom: 20px;
}

.FilterFlight-PriceLimitdescription,
.FilterFlight-durationdescription,
.FilterFlight-flexibleDatedescription {
  margin-left: 10px;
  font-size: 12px;
  margin-bottom: 10px;
}
.FilterFlight-antSilider {
  width: 250px;
  display: flex;
  margin: auto;
}

.FilterFlight-airlinesOptions {
  display: flex;
  flex-direction: column;
}

.transparent-button {
  background: none;
  border: none;
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  margin-top: 5px;
}

.transparent-button:focus {
  outline: none;
}

.transparent-button:hover {
  text-decoration: none;
}
.FilterFlight-Select-Clear-buttonPanel {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.FilterFlight-airlinesCheckboxAll,
.FilterFlight-layoverAirportCheckboxAll {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  gap: 2px;
}

.FilterFligt-durationText,
.FiterFlight-flexibleDateText,
.FilterFlight-layoverAirportText,
.FilterFlight-airlinesText,
.FilterFlight-priceLimitText,
.FilterFlight-stopsText,
.FilterFlight-departureTimeText,
.FilterFlight-arrivalTimeText,
.FilterFlight-priceOptionText {
  margin-left: 10px;
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 700;
}

/* .FilterFlight-departureTimeSlider {
  width: 250px;
} */
.FilterFlight-departureTimeSliderDescription {
  font-family: "Roboto", sans-serif;
  margin-left: 40px;
  font-size: 12px;
  margin-bottom: 5px;
}

.FilterFlight-arrivalTimesubText,
.FilterFlight-departureTimesubText {
  margin-left: 30px;
  margin-bottom: 10px;
}

.FilterFilght-priceOptionCheckboxes {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 12px;
  gap: 5px;
  margin-left: 20px;
}

.label-priceOptionText {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
}

.checkbox-airlines-filter {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border-radius: 5px;
}

@media (max-width: 820px) {
  .FilterFlight-container {
    width: 320px;
  }
}
