.tripstep-container {
  display: flex;
}

.tripstep-content {
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  width: 200px;
}

.tripstep-tail-triangle {
  width: 0px;
  height: 0px;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 24px solid #ffffff !important;
}

.tripstep-head-triangle {
  width: 0px;
  height: 0px;
  border-top: 24px solid white !important;
  border-bottom: 24px solid white !important;
  border-left: 24px solid transparent;
}

.tripstep-icon {
  width: 24px;
  height: 24px;
}

.tripstep-text {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
