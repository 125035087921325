.ConfirmModalComponent-subDisplay {
  background-color: white;
  width: 500px;
  height: 280px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 20px;
  justify-content: space-between;
  bottom: 125px;
  position: relative;
}

.FlightOffer-display {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 20px;
}

.ConfirmModalComponent-title {
  font-size: 24px;
  font-weight: bold;
  padding-top: 12px;
}

.ConfirmModalComponent-buttonPanel {
  display: flex;
  gap: 30px;
  justify-content: center;
}
