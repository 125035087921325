.BookingList-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: fit-content;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 10px;
  border: 1px solid #3c3c3c;
}

/* .flight-class {
  background-color: #4693de;
}

.hotel-class {
  background-color: #9a9c90;
} */

.BookingList-flight,
.BookingList-hotel {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: flex-start;
  width: 100%;
  cursor: pointer;
}

.BookingList-status-approve {
  color: #25d237;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.BookingList-status-reject {
  color: #ff3737;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.BookingList-status-pending {
  color: #c3c55b;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  gap: 5px;
}

.BookingList-nameAndIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.BookingList-hotelName {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.BookingList-passenger {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.BookingList-eachPassenger {
  margin-left: 20px;
}
