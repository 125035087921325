.hotel-offer-item-container {
  display: grid;
  grid-template-columns: 2fr 3fr 2fr;
  column-gap: 10px;
  width: 750px;

  padding: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 10px;
  box-shadow: 2px 2px 4px #818181;
  margin-bottom: 10px;
}

.hotel-offer-item-picture {
  padding: 5px;
}

.hotel-offer-item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.hotel-offer-item-content h4,
h5,
p {
  margin: 0px;
}

.hotel-offer-item-amenities {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 5px;
  row-gap: 5px;
}

.hotel-offer-item-amenity {
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 100px;
  background-color: #d9d9d9;
  font-size: 10px;
}

.hotel-offer-item-room-price {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: space-between;

  border-left: solid 1px #cdcdcd;
}

.hotel-offer-item-room {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.hotel-offer-item-room p {
  font-size: 14px;
}

.hotel-offer-item-room-detail {
  display: flex;
  gap: 5px;
}

.hotel-offer-item-price {
  text-align: end;
}
