.search-page-container {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", sans-serif;
}

.flightSeachBar {
  padding: 15px;
}

.search-flight-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.search-page-displayTotalText {
  font-family: "Roboto", sans-serif;
  margin-left: 20px;
  margin-top: 10px;
}

.search-page-button-to-showFilter {
  width: 40px;
  height: 40px;
  background-color: #0074e4;
  border-radius: 0 50% 50% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  position: relative;
  margin-top: 10px;
  position: sticky;
  top: 0;
}

.search-page-button-to-closeFilter {
  width: 40px;
  height: 40px;
  background-color: #0074e4;
  border-radius: 50% 0 0 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  position: relative;
}

.search-page-flightItemDisplay {
  margin-left: 10px;
  margin-right: 20px;
}

.search-page-filterFlight-section {
  display: flex;
  position: sticky;
  top: 0;
}

.search-page-flightItemContainer {
  /* margin-left:10px;
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  padding:20px;
  overflow: auto;
  width: fit-content;
  max-height: 600px;
  height: fit-content; */
}

.search-page-flightDetailContainer {
  width: 400px;
  margin-left: 20px;
  margin-right: 40px;
}

@media (max-width: 820px) {
  .search-flight-container {
    flex-direction: column;
    gap: 0px;
  }
}

.custom-back-top .ant-back-top-icon {
  font-size: 30px; /* Adjust the size as needed */
}
