.event-pointer {
  cursor: pointer;
}

.calendar-wrapper .fc-view {
  height: 100%; /* Adjust the height as needed */
}

.calendar-wrapper {
  padding-right: 50px;
}
