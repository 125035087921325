.tripgen-summary-page {
  font-family: "Roboto", sans-serif;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.summary-header {
  font-size: 24px;
  font-weight: 1000;
  margin-left: 20px;
}

.flight-container {
  width: 1100px;
  height: 120px;
  border: 2px solid #00adb5;
  border-radius: 10px;
  margin: 20px;
  margin-left: 90px;
  padding: 20px;
}

.flight-first-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 8fr 3fr;
  font-size: 16px;
}

.flight-second-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  font-size: 16px;
}

.flight-third-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 2fr 2fr;
  font-size: 16px;
}

.hotel-container {
  width: 1100px;
  height: 88px;
  border: 2px solid #00adb5;
  border-radius: 10px;
  margin: 20px;
  margin-left: 90px;
  padding: 20px;
}

.hotel-first-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  font-size: 16px;
}

.hotel-second-grid {
  padding: 10px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  font-size: 16px;
}

.tripgen-button-container {
  margin-left: auto;
  margin-right: 0;
  float: right;
  display: flex;
  gap: 20px;
}

.tripgen-summary-btn {
  width: 120px;
  height: 40px;
  border-radius: 5px;
  border: 0px;
  background-color: #00adb5;
  color: #ffffff;
}

.back-btn {
  border: 1px solid #d0d0d0;
  background-color: #ffffff;
  color: #000000;
}

.next-btn {
  background-color: #00adb5;
  color: #ffffff;
}

.tripgen-summary-btn:hover {
  cursor: pointer;
}

.NameTrip-container {
  width: 1100px;
  margin: auto;
  padding: 20px;
  margin-left: 70px;
}
