.creditAndCurrency{
    text-align: right;
}

.wallet-select-bookingdetail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.wallet-select-bookingdetail-container {
    padding: 10px;
    cursor: "pointer";
    padding: 20px;
    width: 700px;
    margin: 20px;

    border-radius: 5px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    box-shadow: 2px 2px 4px #818181;
    font-size: 18px;
    font-family: "Roboto", sans-serif;
}

.wallet-select-bookingdetail-container:hover {
    transform: scale(1.005);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.wallet-left-content{
    display: flex;
    align-items: center;
}