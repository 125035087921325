.traveller-info-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  box-shadow: 2px 2px 4px #818181;
  width: 700px;
  max-height: 600px;
  overflow-y: scroll;
  padding: 25px;
}

.traveller-info-passengers-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.traveller-info-add-passenger-btn {
  display: flex;
  align-self: center;
  gap: 10px;

  width: 180px;
  background-color: #ffffff;
  color: #2780a6;
  border: 0px;
}

.traveller-info-add-passenger-btn:hover {
  cursor: pointer;
}
